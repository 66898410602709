const isGuest = useIsGuest();
const { isOpen, open } = useTaoModals();

const useAutoOpenSignUp = () => {
	const isOpenedSignUpModal = useState("isOpenedSignUpModal");
	const timeoutId = useState<ReturnType<typeof setTimeout> | null>("timeoutId", () => null);

	const startTimeout = () => {
		timeoutId.value = setTimeout(() => {
			if (
				isGuest.value &&
				!isOpenedSignUpModal.value &&
				!isOpen("LazyOModalLogin") &&
				!isOpen("LazyOModalSignup") &&
				!isOpen("LazyOModalOneClickSignup")
			) {
				open("LazyOModalSignup");
				isOpenedSignUpModal.value = true;
			}
		}, 10000);
	};

	const stopTimeout = () => {
		if (timeoutId.value) {
			clearTimeout(timeoutId.value);
			timeoutId.value = null;
		}
	};

	return {
		startTimeout,
		stopTimeout
	};
};

export default useAutoOpenSignUp;
